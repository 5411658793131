import type { PageProps } from 'gatsby'
import { BannerFull } from 'src/components/department/BannerFull'
import { BannerMood } from 'src/components/department/BannerMood'
import { NavigateBySports } from 'src/components/department/NavigateBySports'
import { NavigateByCategories } from 'src/components/department/NavigateByCategories'
import { CollectionShelf } from 'src/components/common/CollectionShelf'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { getCMSPageByContentType } from 'src/utils/getCMSPageDataByContentType'
import type { DepartmentSections } from 'src/components/department/types/sections'
import type { ComponentType } from 'react'
import { Pictos } from 'src/components/common/Pictos'

interface ServerDataSportPage {
  cmsData: {
    sections: DepartmentSections[]
  }
}

interface SportPageProps extends PageProps {
  serverData: ServerDataSportPage
}

const COMPONENTS: Record<string, ComponentType<any>> = {
  ' Banner Full [Departamento]': BannerFull,
  ' Banners Mood [Departamento]': BannerMood,
  'Navegue por esporte [Departamento]': NavigateBySports,
  'Navegue por categoria [Departamento]': NavigateByCategories,
  ' Pictos [Departamento]': Pictos,
  ' Vitrine - Nossos queridinhos [Departamento]': CollectionShelf,
}

function SportPage(props: SportPageProps) {
  const { cmsData } = props.serverData

  if (!cmsData) {
    return null
  }

  const { sections } = cmsData

  return (
    <div>
      <GatsbySeo noindex nofollow />
      {sections?.map(({ name, data }) => {
        const Component = COMPONENTS[name]

        if (!Component) {
          console.info(
            `Could not find component for block ${name}. Add a new component for this block or remove it from the CMS`
          )

          return <></>
        }

        return <Component key={`cms-section-${name}`} {...data} />
      })}
    </div>
  )
}

export const getServerData = async ({
  params: { slug },
}: {
  query: Record<string, string>
  params: Record<string, string>
}) =>
  getCMSPageByContentType({
    slug,
    contentType: 'pagina-esportes',
  })

export default SportPage
